import { ReactComponent as CompanyIcon } from '../img/fav.svg'
import { WhatsAppWidget } from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

export const WhatsAppComponent = () => {

  return (
    <WhatsAppWidget CompanyIcon={CompanyIcon} companyName={'Guadalseu'} phoneNumber="34916638335" message='Hola!👋🏼 ¿Qué podemos hacer por tí?' sendButtonText='Enviar' inputPlaceHolder='Escribe aquí tu mensaje...' replyTimeText='' />
  )
}
